<template>
  <div v-if="countryList && countryList.length > 0" class="country-container">
    <div v-if="url && title" class="title" style="justify-content: flex-end">
      <!-- <h1>{{ title }}</h1> -->
      <f7-link :href="url">
        {{ $t.getTranslation("LBL_VIEW_ALL") }}
        <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
      </f7-link>
    </div>

    <div class="container">
      <f7-swiper v-if="countryList?.length > 4" :slides-per-view="'auto'" :space-between="0" navigation loop :autoplay="{ delay: 5000 }">
        <f7-swiper-slide v-for="country in countryList" :key="'pbn_' + country.CountryId">
          <CountryCardComponent :data="country" />
        </f7-swiper-slide>
      </f7-swiper>

      <div v-else class="country-card-container">
        <div v-for="country in countryList" :key="'pbn_' + country.CountryId">
          <CountryCardComponent :data="country" />
        </div>
      </div>
    </div>

    <!-- <div v-if="divider && countryList?.length > 0" class="linedivider"></div> -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

export default defineComponent({
  name: "CountryListComponent",
  components: {
    CountryCardComponent: defineAsyncComponent(() => import(/* webpackChunkName: "country-card" */ /* webpackMode: "lazy" */ "@/components/cards/CountryCardComponent.vue")),
  },
  props: {
    type: String,
    divider: Boolean,
    size: { type: Number, default: 6 },
    title: String,
    url: String,
    mode: { type: String, default: "" },
  },
  setup(props) {
    const store = useStore();

    store.dispatch("config/fetchData", { params: { countryOfOriginList: 1 } });
    const languageCode = computed(() => store.getters["translation/getLanguage"]);

    const countryList = ref([]);

    let page = 1;
    let size = 30;
    let lastPage = 1;

    const getData = async (reset = false) => {
      if (reset) {
        page = 1;
        lastPage = 1;
        countryList.value = [];
      }

      let getParams = {
        page: page,
        size: size,
        LanguageCode: languageCode.value,
        mode: props.mode || "",
      };

      let ret = await get(`/country/list`, getParams);

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          countryList.value.push(item);
        }

        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getData() : false;
    };

    onMounted(() => {
      getData();
    });

    return {
      countryList,
      loadMore,
      getData,
    };
  },
});
</script>

<style>
.banner-container .linedivider {
  margin-top: 30px;
}
</style>
